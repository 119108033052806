<template>
  <div>
     <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col :span="12" :offset="2">
          <h2> Tag засварлах </h2>
        </el-col>
      </el-row>
    </header>
     <el-form label-width="120px" :model="tagData" :rules="tagRules" ref="tagData">
        <el-row :gutter="20">
        <el-col :span="20" :offset="2">
        <div class="panel">
        <div class="panel-item">
     <el-row :gutter="20">
    <el-col :span="12">
        <el-form-item label="Монгол нэр" prop="name_mon">
        <el-input type="text" v-model="tagData.name_mon" placeholder="Шинэ ..."></el-input>
        </el-form-item>
    </el-col>
    <el-col :span="12">
    <el-form-item label="Англи нэр" prop="name_eng">
        <el-input type="text" v-model="tagData.name_eng" placeholder="New ..."></el-input>
    </el-form-item>
    </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="Төрөл" prop="name_mon">
          <el-select v-model="tagData.type" placeholder="Төрөл сонгох ..." @change="handleType">
            <el-option
              v-for="(item, indexTag) in tagType"
              :key="indexTag"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-select v-model="tagData.is_image" placeholder="Төрөл сонгох ..." @change="handleIsImage">
            <el-option
              v-for="(item, indexTag1) in tagImageOrText"
              :key="indexTag1"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="tagData.type === 'category' && tagData.is_image === 'image'">
        <div style="display: flex">
        <el-form-item label="Зүүн дээд зураг">
          <el-upload
            list-type="picture-card"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG"
            :show-file-list="false"
            action=""
            :limit="1"
            :auto-upload="false"
            :on-change="(file) => getFile(file,'image', 'leftTop')"
            :on-remove="(file) => handleRemove(file, 'image')">
            <img v-if="tagData.image.left_top" :src="tagData.image.left_top" class="avatar" height="150" width="150">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <loader :active="isUploadingLeftTop" />
        </el-form-item>
        <el-form-item label="Баруун дээд зураг">
          <el-upload
            list-type="picture-card"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG"
            :show-file-list="false"
            action=""
            :limit="1"
            :auto-upload="false"
            :on-change="(file) => getFile(file,'image', 'rightTop')"
            :on-remove="(file) => handleRemove(file, 'image')">
            <img v-if="tagData.image.right_top" :src="tagData.image.right_top" class="avatar" height="150" width="150">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <loader :active="isUploadingRightTop" />
        </el-form-item>
        </div>
        <div style="display: flex">
        <el-form-item label="Зүүн доод зураг">
          <el-upload
            accept=".jpg,.jpeg,.png,.JPG,.JPEG"
            list-type="picture-card"
            :show-file-list="false"
            action=""
            :limit="1"
            :auto-upload="false"
            :on-change="(file) => getFile(file,'image', 'leftBottom')">
            <img v-if="tagData.image.left_bottom" :src="tagData.image.left_bottom" class="avatar" height="150" width="150">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <loader :active="isUploadingLeftBottom" />
        </el-form-item>
        <el-form-item label="Баруун доод зураг">
          <el-upload
            list-type="picture-card"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG"
            action=""
            :limit="1"
            :show-file-list="false"
            :auto-upload="false"
            :on-change="(file) => getFile(file,'image', 'rightBottom')"
            :on-remove="(file) => handleRemove(file, 'image')">
            <img v-if="tagData.image.right_bottom" :src="tagData.image.right_bottom" class="avatar" height="150" width="150">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <loader :active="isUploadingRightBottom" />
        </el-form-item>
        </div>
      </el-col>
    </el-row>
      <el-row :gutter="20" v-if="tagData.is_image === 'text'">
        <el-col :span="8">
          <el-form-item label="Дэвсгэр өнгө сонгох" prop="color" v-if="tagData.is_image === 'text'">
              <el-select placeholder="Өнгө сонгох" v-model="tagData.color">
                <el-option
                  v-for="(color, indexBackground) in colorList"
                  :key="indexBackground"
                  :label="color.name_mon"
                  :value="color.hex_code">
                </el-option>
              </el-select>
          <!-- <el-color-picker v-model="tagData.color" @change="onChangeColor"></el-color-picker> -->
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-tag type="text" :color="tagData.color" size="medium">Сонгосон өнгө</el-tag>
        </el-col>
        <el-col :span="8">
        <el-form-item label="Text өнгө сонгох" prop="text_color" >
          <el-select placeholder="Өнгө сонгох"  v-model="tagData.text_color">
            <el-option
              v-for="(color, indexText) in colorList"
              :key="indexText"
              :label="color.name_mon"
              :value="color.hex_code">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-tag type="text" :color="tagData.text_color" size="medium">Сонгосон өнгө</el-tag>
      </el-col>
        </el-row>
        <el-row :gutter="20" v-if="tagData.is_image === 'text'">
            <el-col :span="8">
            <el-form-item label="Өнгөний код" prop="hex_code">
            <el-input disabled v-model="tagData.hex_code" placeholder="#6BD90B"></el-input>
            </el-form-item>
        </el-col>
        <el-col :span="12" :offset="4">
          <el-form-item label="Байрлал">
          <el-select v-model="tagData.position" placeholder="Байрлал сонгох">
            <el-option
              v-for="(item, indexPosition) in position"
              :key="indexPosition"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          </el-form-item>
        </el-col>
        </el-row>
        </div>
        </div>
        </el-col>
        </el-row>
       <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col :span="10" :offset="2">
                <el-button class="text-left" type="danger" @click="onChange('delete')">Устгах</el-button>
              </el-col>
              <el-col :span="10" class="text-right">
                <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
                <el-button type="success" @click="onChange('update')"
                >Шинэчлэх</el-button>
              </el-col>
            </el-row>
          </div>
    </el-form>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link :to="'/tagList' + '?page=' + this.$route.query.page + '&size=' + this.$route.query.size + '&search=' + this.$route.query.search + '&field=' + this.$route.query.field + '&sort=' + this.$route.query.sort + '&type=' + this.$route.query.type">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
    <!-- delete -->
    <el-dialog
        :title="isChanging ? 'Tag шинэчлэх' : 'Tag устгах'"
        :visible.sync="confirmDeleteTagDialog"
        width="50%"
      >
        <div>
          <strong>{{tagData.name_mon}}</strong> Tag - ийг {{ isChanging ? 'шинэчлэхдээ' : 'устгахдаа'}} шалтгаанаа заавал бичнэ үү?
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confirmDeleteTagDialog = false" class="mr10"
            >Болих</el-button
          >
          <el-button  v-if="isChanging" :disabled="isDisabled" type="success" @click="onPressUpdateTag('tagData', 'tagRules')">
            Хадгалах
          </el-button>
          <el-button v-else type="danger" :disabled="isDisabled" @click="deleteTag('tagData', 'tagRules')">
            Устгах
          </el-button>
        </span>
        <el-row :gutter="20">
          <el-col :span="24" class="mt20">
            <el-form :model="tagData" :rules="tagRules" ref="tagData">
              <el-form-item prop="desc" label="Шалтгаан">
                <el-input v-model="tagData.desc" type="textarea" :rows="2" :placeholder="isChanging ? 'Шинэчилж буй шалтгаанаа бичнэ үү ...' : 'Устгаж буй шалтгаанаа бичнэ үү ...'"></el-input>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-dialog>
</div>
</template>

<script>
import services from '../../../helpers/services'
import { handlers } from '../../../helpers/custom'
import loader from '../../../components/common/loader'
export default {
  name: 'updateTag',
  components: {
    loader
  },
  created () {
    if (this.$route.params.id) {
      this.getOneTag(this.$route.params.id)
      this.getColors(0, 1000)
    }
  },
  data () {
    return {
      color: '#6BD90B',
      isDisabled: false,
      confirmLeaveForm: false,
      isChanging: false,
      confirmDeleteTagDialog: false,
      colorList: [],
      isUploadingLeftTop: false,
      isUploadingRightTop: false,
      isUploadingLeftBottom: false,
      isUploadingRightBottom: false,
      tempImages: [],
      tagData: {
        name_mon: '',
        name_eng: '',
        id: '',
        desc: '',
        color: '',
        design: '',
        hex_code: '',
        type: '',
        is_image: '',
        image: {
          left_top: '',
          right_top: '',
          left_bottom: '',
          right_bottom: ''
        }
      },
      position: [{
        value: 'left_top',
        label: 'Зүүн дээр'
      }, {
        value: 'left_bottom',
        label: 'Зүүн доор'
      }, {
        value: 'right_top',
        label: 'Баруун дээр'
      }, {
        value: 'right_bottom',
        label: 'Баруун доор'
      }],
      tagType: [{
        value: 'category',
        label: 'Апп категори'
      }, {
        value: 'product',
        label: 'Бүтээгдэхүүн'
      }],
      tagRules: {
        desc: [
          { required: true, message: 'Монгол нэрээ оруулна уу', trigger: 'blur' }
        ],
        name_mon: [
          { required: true, message: 'Монгол нэрээ оруулна уу', trigger: 'blur' }
        ],
        name_eng: [
          { required: true, message: 'Англи нэрээ оруулна уу', trigger: 'blur' }
        ],
        hex_code: [
          { required: true, message: 'Өнгөө сонгоно уу', trigger: 'blur' }
        ]
      },
      tagImageOrText: [{
        value: 'image',
        label: 'Зураг'
      }, {
        value: 'text',
        label: 'Text'
      }]
    }
  },
  methods: {
    handleIsImage (data) {
      if (data === 'text') {
        this.tagData.image = {
          left_top: '',
          right_top: '',
          left_bottom: '',
          right_bottom: ''
        }
      }
    },
    handleType (type) {
      if (type === 'product') {
        this.tagData.is_image = 'text'
      }
    },
    handleRemove (file, type) {
      var tempId = 0
      if (type === 'image') {
        this.tempImages.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImages.splice(tempId, 1)
        this.tagData.image = ''
      } else {
        this.$message.error('Уучлаарай алдаа гарлаа!')
      }
    },

    getFile (file, type, position) {
      if (position === 'leftTop') this.isUploadingLeftTop = true
      else if (position === 'rightTop') this.isUploadingRightTop = true
      else if (position === 'leftBottom') this.isUploadingLeftBottom = true
      else if (position === 'rightBottom') this.isUploadingRightBottom = true
      handlers.getBase64(file.raw).then(res => {
        const tempBody = {
          image: res,
          filePath: file.name
        }
        services.imageUpload(tempBody).then(response => {
          if (response.status === 'success') {
            this.isUploadingLeftTop = false
            this.isUploadingRightTop = false
            this.isUploadingLeftBottom = false
            this.isUploadingRightBottom = false
            if (type === 'image') {
              this.tempImages.push({
                url: response.url
              })
              if (position === 'leftTop') this.tagData.image.left_top = response.url
              else if (position === 'rightTop') this.tagData.image.right_top = response.url
              else if (position === 'leftBottom') this.tagData.image.left_bottom = response.url
              else if (position === 'rightBottom') this.tagData.image.right_bottom = response.url
            }
          } else {
            this.$message.error('Уучлаарай алдаа гарлаа!')
          }
        })
      })
    },
    getColors (from, size) {
      const query = '?from=' + from + '&size=' + size
      services.getColors(query).then(data => {
        this.colorList = data.datas
      })
    },
    onChange (type) {
      if (type === 'update') {
        this.confirmDeleteTagDialog = true
        this.isChanging = true
      } else {
        this.confirmDeleteTagDialog = true
        this.isChanging = false
      }
    },
    onChangeColor (color) {
      this.tagData.hex_code = color
    },
    deleteTag () {
      this.isDisabled = true
      services.deleteTag({
        id: this.tagData.id,
        desc: this.tagData.desc
      }).then(response => {
        this.isDisabled = false
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', `${this.tagData.id} Амжилттай устлаа`, 'success')
          this.$router.push({
            name: 'tagList'
          })
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа', `${this.tagData.id}  Устгахад алдаа гарлаа`, 'error')
          this.loading = false
        } else {
          return false
        }
      })
    },
    onPressUpdateTag () {
      if (this.tagData.id !== '') {
        this.isDisabled = true
        const body = this.tagData
        if (body.type === 'product') {
          body.image = ''
        } else if (body.type === 'category' && !body.image.left_top && !body.image.right_top && !body.image.left_bottom && !body.image.right_bottom) {
          body.image = null
        }
        services.updateTag(body).then((data) => {
          this.isDisabled = false
          this.alertReporter(
            data.status === 'success' ? 'Амжилттай' : 'Уучлаарай',
            data.message,
            data.status
          )
          if (data.status === 'success') {
            this.isDisabled = false
            this.$router.push({ name: 'tagList' })
          } else if (data.status === 'error') {
            this.alertReporter('Алдаа', data.message, 'error')
          } else {
            return false
          }
        })
      }
    },
    getOneTag (id) {
      services.getOneTag(id).then((response) => {
        if (response.status === 'success') {
          this.tagData = response.data
          if (response.data.image !== null) {
            this.tempImages.push({ url: this.tagData.image })
          }
          this.loading = false
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа', response.message, 'error')
          this.loading = false
        }
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    }
  }
}
</script>

<style></style>
